import { UserData } from '../../global/interfaces';
import Icon from '../Icon/Icon';
import './get-started.scss';

const Step = ({
  title,
  link,
  type,
  isDone,
}: {
  title: string;
  link: string;
  isDone: boolean;
  type: 'LINK' | 'DOWNLOAD';
}) => {
  return (
    <div className={`step ${isDone && 'step__done'}`}>
      <a href={link} target={type === 'LINK' ? '_blank' : undefined} rel="noreferrer" download={type === 'DOWNLOAD'}>
        {title}
        <Icon name={type === 'LINK' ? 'link' : 'download'} size={14} />
      </a>
      {isDone && <Icon name="checkmark" size={14} />}
    </div>
  );
};

const GetStarted = ({ user }: { user: UserData }) => {
  return (
    <div className="get-started">
      <div className="get-started_heading">
        <h3>Get started</h3>
      </div>
      <div className="get-started_dropdown">
        {user.gettingStartedSteps.map((step) => {
          return <Step key={step.title} title={step.title} link={step.url} type={step.type} isDone={step.isDone} />;
        })}
      </div>
    </div>
  );
};

export default GetStarted;

import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import AsideMenu from '../../../../components/aside-menu/aside-menu';
import { GameFormData } from '../../../../components/modal/new-game-modal/new-game-modal';
import BasicInformationStep from '../../../../components/modal/new-game-modal/steps/basic-information-step/basic-information-step';
import GameDetailsStep from '../../../../components/modal/new-game-modal/steps/game-details-step/game-details-step';
import { UserRole } from '../../../../global/consts';
import { UPSERT_GAME_MUTATION } from '../../../../global/gql/mutations';
import {
  GAME_SEARCH_QUERY,
  GET_GAMES_QUERY,
  GET_ORG_GAMES_QUERY,
  GET_USER_QUERY,
} from '../../../../global/gql/queries';
import { GamesData, MenuItems, UserData } from '../../../../global/interfaces';
import { useFileUpload } from '../../../../hooks';
import { useReneMutation } from '../../../../hooks/useReneMutation';
import { useReneQuery } from '../../../../hooks/useReneQuery';
import { isRoleAllowed, removeTypename } from '../../../../utils';
import ApiCredentials from './api-credentials/api-credentials';
import './organization-game-settings.scss';

type SelectedMenuItem = 'basicInfo' | 'platformsAndReach' | 'apiCredentials';

const menuItems: MenuItems = {
  basicInfo: { name: 'basicInfo', icon: 'info', label: 'Basic Info' },
  platformsAndReach: { name: 'platformsAndReach', icon: 'upwards-graph', label: 'Platforms & Reach' },
  apiCredentials: { name: 'apiCredentials', icon: 'transparent-cube', label: 'Api Credentials' },
};

const OrganizationGameSettings = () => {
  const params = useParams();
  const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedMenuItem>('basicInfo');

  const [formData, setFormData] = useState<GameFormData>({
    basicInfo: {
      title: '',
      description: '',
      genres: [],
      iconImage: undefined,
      coverImage: undefined,
    },
    gameDetails: {
      operatingSystems: [],
      iosData: { bundleId: '', url: '' },
      androidData: { url: '', packageName: '' },
      webData: { url: '' },
      iabCategories: [],
      monthlyActiveUsers: undefined,
      topCountryDistribution: [],
    },
  });

  const { data, refetch } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
    onCompleted: async (data) => {
      const game = data?.Games.items[0];
      const operatingSystems = [];
      if (game?.iosData?.url) operatingSystems.push('IOS');
      if (game?.androidData?.url) operatingSystems.push('ANDROID');
      if (game?.webData?.url) operatingSystems.push('WEB');
      setFormData({
        basicInfo: {
          title: game?.name || '',
          description: game?.description || '',
          genres: game?.genres || [],
          iconImageURLFromBackend: game?.image?.url || '',
          coverImageURLFromBackend: game?.coverImage?.url || '',
        },
        gameDetails: {
          operatingSystems,
          iosData: {
            bundleId: game?.iosData?.bundleId || '',
            url: game?.iosData?.url || '',
          },
          androidData: {
            url: game?.androidData?.url || '',
            packageName: game?.androidData?.packageName || '',
          },
          webData: { url: game?.webData?.url || '' },
          iabCategories: game?.contentTags || [],
          monthlyActiveUsers: game?.monthlyActiveUsers,
          topCountryDistribution:
            game?.topCountryDistribution.map((cd) => ({
              countryCode: cd.countryCode,
              monthlyActiveUsers: cd.monthlyActiveUsers,
            })) || [],
        },
      });
    },
  });

  const updateFormData = useCallback(<K extends keyof GameFormData>(step: K, data: Partial<GameFormData[K]>) => {
    setFormData((prev) => ({
      ...prev,
      [step]: { ...prev[step], ...data },
    }));
  }, []);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const isUserAllowedToUpsert = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]);
  const isAgreedToLicense = user?.User.isAgreedToLicense as boolean;

  const handleTabSelect = (value: string) => {
    setSelectedMenuItem(value as SelectedMenuItem);
  };

  const uploadFile = useFileUpload();

  const apolloClient = useApolloClient();

  const [upsertGame, { loading: submitLoading }] = useReneMutation(UPSERT_GAME_MUTATION, {
    onCompleted: async (data) => {
      if (
        (!data.UpsertGame.image.fileId && formData.basicInfo.iconImage) ||
        (!data.UpsertGame.coverImage?.fileId && formData.basicInfo.coverImage)
      ) {
        const uploads = [];
        if (formData.basicInfo.iconImage) {
          uploads.push(uploadFile(data.UpsertGame.image.uploadUrl, formData.basicInfo.iconImage));
        }
        if (data.UpsertGame.coverImage?.uploadUrl && formData.basicInfo.coverImage) {
          uploads.push(uploadFile(data.UpsertGame.coverImage.uploadUrl, formData.basicInfo.coverImage));
        }
        await Promise.all(uploads);
      }
      await refetch();
      await apolloClient.refetchQueries({
        include: [GAME_SEARCH_QUERY, GET_GAMES_QUERY, GET_ORG_GAMES_QUERY, 'Games'],
      });
    },
  });
  const handleSubmit = async () => {
    let variables = { gameId: params.gameId, isActive: true };

    variables = removeTypename({
      ...variables,
      name: formData.basicInfo.title,
      description: formData.basicInfo.description,
      iosData: formData.gameDetails.iosData?.bundleId ? formData.gameDetails.iosData : undefined,
      androidData: formData.gameDetails.androidData?.packageName ? formData.gameDetails.androidData : undefined,
      webData: formData.gameDetails.webData?.url ? formData.gameDetails.webData : undefined,
      contentTags:
        formData.gameDetails.iabCategories?.map((ct) => ({
          name: ct.name,
          taxonomyContentId: ct.taxonomyContentId,
        })) ?? [],
      monthlyActiveUsers: formData.gameDetails.monthlyActiveUsers,
      topCountryDistribution: formData.gameDetails.topCountryDistribution,
      genres: formData.basicInfo.genres,
    });

    if (formData.basicInfo.iconImage) {
      variables = removeTypename({
        ...variables,
        image: { extension: formData.basicInfo.iconImage.type.split('/')[1] },
      });
    }

    if (formData.basicInfo.coverImage) {
      variables = removeTypename({
        ...variables,
        coverImage: { extension: formData.basicInfo.coverImage.type.split('/')[1] },
      });
    }

    await upsertGame({ variables });
  };

  const submitButton = (btnText: string) => (
    <button type="button" className="btn-primary-solid submit-step" onClick={handleSubmit} disabled={submitLoading}>
      {btnText}
    </button>
  );

  const renderSelectedMenuItem = (selectedMenuItem: SelectedMenuItem) => {
    switch (selectedMenuItem) {
      case 'basicInfo':
        return (
          <div className="organization-game-settings__main__step-container">
            <BasicInformationStep formData={formData} updateFormData={updateFormData} />
            {submitButton('Save Basic Info')}
          </div>
        );
      case 'platformsAndReach':
        return (
          <div className="organization-game-settings__main__step-container">
            <GameDetailsStep formData={formData} updateFormData={updateFormData} />
            {submitButton('Save Platforms & Reach')}
          </div>
        );
      case 'apiCredentials':
        return (
          <ApiCredentials
            isAgreedToLicense={isAgreedToLicense}
            isUserAllowedToUpsert={isUserAllowedToUpsert}
            game={data?.Games.items[0]}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="organization-game-settings">
      <div className="organization-game-settings__aside">
        <AsideMenu items={menuItems} selectedItem={selectedMenuItem} handleItemSelect={handleTabSelect} />
      </div>
      <div className="organization-game-settings__main">{renderSelectedMenuItem(selectedMenuItem)}</div>
    </div>
  );
};

export default OrganizationGameSettings;

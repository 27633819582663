import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { MenuItems } from '../../../../global/interfaces';
import { useParams } from 'react-router-dom';
import { GET_BRANDED_OBJECT_PLACEMENTS_QUERY } from '../../../../global/gql/queries';
import AsideMenu from '../../../../components/aside-menu/aside-menu';
import AdSurfaceList from '../../../../components/asset/ad-surface-list/ad-surface-list';
import BrandedObjectsList from '../../../../components/asset/branded-objects-list/branded-objects-list';
import './organization-game-ad-placement.scss';

// TODO: update icons
const MENU_ITEMS: MenuItems = {
  branded_objects: {
    name: 'branded_objects',
    label: 'Branded objects',
    icon: '3d-object',
  },
  ad_surfaces: {
    name: 'ad_surfaces',
    label: 'Ad surfaces',
    icon: 'ad-surface',
  },
};

type AdPlacementMenuItem = 'branded_objects' | 'ad_surfaces';
const stringIsMenuItem = (str: string): str is AdPlacementMenuItem => Object.keys(MENU_ITEMS).includes(str);

const OrganizationGameAdPlacement = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<AdPlacementMenuItem>('branded_objects');
  const params = useParams();

  const { data, loading } = useQuery(GET_BRANDED_OBJECT_PLACEMENTS_QUERY, {
    variables: {
      input: { gameId: params.gameId },
    },
  });

  const handleSelect = (value: string) => {
    if (stringIsMenuItem(value)) {
      setSelectedMenuItem(value);
    }
  };

  return (
    <div className="organization-game-ad-placement" style={{ display: 'flex', flexDirection: 'row' }}>
      <AsideMenu items={MENU_ITEMS} selectedItem={selectedMenuItem} handleItemSelect={handleSelect} />
      <div className="organization-game-ad-placement__content">
        {selectedMenuItem === 'ad_surfaces' ? (
          <AdSurfaceList id={params.gameId} isUserAllowedToUpsert={true} setOpenMobileMenu={() => {}} type="gameId" />
        ) : (
          <BrandedObjectsList brandedObjectPlacements={data?.BrandedObjectPlacements?.items || []} loading={loading} />
        )}
      </div>
    </div>
  );
};

export default OrganizationGameAdPlacement;

import { useState } from 'react';
import { GET_ADS } from '../../../../../global/gql/queries';
import { useReneMutation, useReneQuery } from '../../../../../hooks';
import { CampaignFormData } from '../../create-campaign-modal';
import { AdCampaignCheckpoint, CampaignStatus } from '../../../../../global/consts';
import {
  AdData,
  BrandedObjectData,
  CampaignData,
  CampaignsData,
  Dispatcher,
  OrganizationData,
  Refetch,
} from '../../../../../global/interfaces';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../../../global/gql/mutations';
import Icon from '../../../../Icon/Icon';
import Modal from '../../../modal';
import Search from '../../../../search/search';
import Spinner from '../../../../spinner/spinner';
import Checkbox from '../../../../checkbox/checkbox';
import CreateAdModal from '../../../create-ad-modal/create-ad-modal';

import './select-ad.scss';

interface SelectAdProps {
  data: {
    selectedAdsIds: CampaignFormData['selectedAdsIds'];
    selectedBrandedObjectsIds: CampaignFormData['selectedBrandedObjectsIds'];
  };
  status: CampaignStatus;
  adCampaignId: string;
  setActiveStep: (step: number) => void;
  setFormData: Dispatcher<CampaignFormData>;
  handleDeleteAdCampaign: () => void;
  deleteAdCampaignLoading: boolean;
  refetch: Refetch<
    | {
        Organization: OrganizationData;
      }
    | {
        AdCampaigns: CampaignsData;
      }
    | undefined
  >;
}

interface AdListItemProps {
  id: string;
  name: string;
  imageUrl?: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
  type: string;
}

const AdListItem: React.FC<AdListItemProps> = ({ id, name, imageUrl, isSelected, onSelect, type }) => (
  <div className="select-ad__ads_list_item" onClick={() => onSelect(id)} role="button" tabIndex={0}>
    <div>
      <Checkbox value={isSelected} setValue={() => {}} />
      <img src={imageUrl} alt={name} />
    </div>
    <p>{name}</p>
    <p>{type}</p>
  </div>
);

const SelectAd: React.FC<SelectAdProps> = ({
  data,
  status,
  adCampaignId,
  setActiveStep,
  setFormData,
  handleDeleteAdCampaign,
  deleteAdCampaignLoading,
  refetch,
}) => {
  const [selectedAds, setSelectedAds] = useState<string[]>(data.selectedAdsIds || []);
  const [selectedBrandedObjects, setSelectedBrandedObjects] = useState<string[]>(data.selectedBrandedObjectsIds || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [adsSearchTerm, setAdsSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'branded-objects' | 'surface'>('all');

  const {
    data: adsData,
    refetch: refetchAds,
    loading: adsLoading,
  } = useReneQuery<{
    Ads: { ads: AdData[]; brandedObjects: BrandedObjectData[] };
  }>(GET_ADS);

  const [upsertCampaign, { loading: upsertCampaignLoading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted(data: { UpsertAdCampaign: CampaignData }) {
      setFormData((prev) => ({
        ...prev,
        adCampaignId: data.UpsertAdCampaign.adCampaignId,
        status: data.UpsertAdCampaign.status as CampaignStatus,
        selectedAds: data.UpsertAdCampaign.ads.items,
        selectedAdsIds: data.UpsertAdCampaign.ads.items.map((ad) => ad.adId),
        selectedBrandedObjects: data.UpsertAdCampaign.brandedObjects.items,
        selectedBrandedObjectsIds: data.UpsertAdCampaign.brandedObjects.items.map(
          (brandedObject) => brandedObject.brandedObjectId,
        ),
      }));
      setActiveStep(3);
      refetch();
    },
  });

  // const handleSearch = useCallback((data: string) => {
  //   setAdsSearchTerm(data);
  // }, []);

  const handleAdSelection = (adId: string) => {
    setSelectedAds((prev) => {
      if (prev.includes(adId)) {
        return prev.filter((id) => id !== adId);
      }
      return [...prev, adId];
    });
  };

  const handleBrandedObjectSelection = (brandedObjectId: string) => {
    setSelectedBrandedObjects((prev) => {
      if (prev.includes(brandedObjectId)) {
        return prev.filter((id) => id !== brandedObjectId);
      }
      return [...prev, brandedObjectId];
    });
  };

  const handleSelectAll = () => {
    if (adsData?.Ads?.ads) {
      if (selectedFilter === 'all') {
        const allAdsSelected =
          selectedAds.length === adsData.Ads.ads.length &&
          selectedBrandedObjects.length === adsData.Ads.brandedObjects.length;

        if (allAdsSelected) {
          setSelectedAds([]);
          setSelectedBrandedObjects([]);
        } else {
          setSelectedAds(adsData.Ads.ads.map((ad: AdData) => ad.adId));
          setSelectedBrandedObjects(
            adsData.Ads.brandedObjects.map((brandedObject: BrandedObjectData) => brandedObject.brandedObjectId),
          );
        }
      }

      if (selectedFilter === 'branded-objects') {
        const allBrandedObjectsSelected = selectedBrandedObjects.length === adsData.Ads.brandedObjects.length;

        if (allBrandedObjectsSelected) {
          setSelectedBrandedObjects([]);
        } else {
          setSelectedBrandedObjects(
            adsData.Ads.brandedObjects.map((brandedObject: BrandedObjectData) => brandedObject.brandedObjectId),
          );
        }
      }

      if (selectedFilter === 'surface') {
        const allAdsSelected = selectedAds.length === adsData.Ads.ads.length;

        if (allAdsSelected) {
          setSelectedAds([]);
        } else {
          setSelectedAds(adsData.Ads.ads.map((ad: AdData) => ad.adId));
        }
      }
    }
  };

  const saveCampaign = () => {
    let variables = {
      adCampaignId: adCampaignId,
      adsIds: selectedAds,
      brandedObjectIds: selectedBrandedObjects,
      checkpoint: AdCampaignCheckpoint.ADS_PLACEMENT,
    };
    upsertCampaign({ variables });
  };

  return (
    <div className="select-ad">
      <h2>Select your ads</h2>
      <h3>Choose existing ads from your library or upload new.</h3>
      <div className="select-ad__nav">
        <Search callback={() => {}} apiSearch disabled />
        <div className="select-ad__nav_filters">
          <button onClick={() => setSelectedFilter('all')} className={selectedFilter === 'all' ? 'active' : ''}>
            All
          </button>
          <button
            onClick={() => setSelectedFilter('branded-objects')}
            className={selectedFilter === 'branded-objects' ? 'active' : ''}
          >
            Branded Objects
          </button>
          <button onClick={() => setSelectedFilter('surface')} className={selectedFilter === 'surface' ? 'active' : ''}>
            Image/Video
          </button>
        </div>
        <button className="btn-primary-ghost" onClick={() => setIsModalOpen(true)}>
          <Icon name="plus" />
          New Ad
        </button>
      </div>
      <div className="select-ad__ads">
        <div className="select-ad__ads_heading">
          <div>
            <p>Library</p>
            <Icon size={20} name="chevron-up" />
          </div>
          <div>
            <Checkbox
              value={
                !!(
                  selectedAds.length &&
                  selectedAds.length === adsData?.Ads?.ads?.length &&
                  selectedBrandedObjects.length &&
                  selectedBrandedObjects.length === adsData?.Ads?.brandedObjects?.length
                )
              }
              setValue={handleSelectAll}
            />
            <p>Select all</p>
          </div>
        </div>
        <div className="select-ad__ads_list">
          {adsLoading ? <Spinner size="lg" /> : null}
          {(selectedFilter === 'all' || selectedFilter === 'surface') &&
            adsData?.Ads?.ads?.map((ad: AdData) => (
              <AdListItem
                key={ad.adId}
                id={ad.adId}
                name={ad.name}
                imageUrl={ad.banner?.url}
                isSelected={selectedAds.includes(ad.adId)}
                onSelect={handleAdSelection}
                type="Image/Video"
              />
            ))}
          {(selectedFilter === 'all' || selectedFilter === 'branded-objects') &&
            adsData?.Ads?.brandedObjects?.map((brandedObject: BrandedObjectData) => (
              <AdListItem
                key={brandedObject.brandedObjectId}
                id={brandedObject.brandedObjectId}
                name={brandedObject.name}
                imageUrl={brandedObject.image?.url}
                isSelected={selectedBrandedObjects.includes(brandedObject.brandedObjectId)}
                onSelect={handleBrandedObjectSelection}
                type="Branded Object"
              />
            ))}
        </div>
      </div>
      <div className="create-campaign-modal__main_footer">
        <div>
          {selectedAds.length > 0 || selectedBrandedObjects.length > 0 ? (
            <div className="select-ad__selected_ads">
              <button
                className="btn-neutral-ghost"
                onClick={() => {
                  setSelectedAds([]);
                  setSelectedBrandedObjects([]);
                }}
              >
                <Icon name="close" />
              </button>
              <p>{selectedAds.length + selectedBrandedObjects.length} selected</p>
            </div>
          ) : (
            status === CampaignStatus.DRAFT && (
              <button className="btn-negative-ghost" onClick={handleDeleteAdCampaign}>
                {deleteAdCampaignLoading ? <Spinner size="sm" /> : 'Discard'}
              </button>
            )
          )}
        </div>
        <div>
          {selectedAds.length > 0 || selectedBrandedObjects.length > 0 ? null : (
            <button className="btn-neutral-ghost" onClick={() => setActiveStep(1)}>
              Back
            </button>
          )}
          <button
            className="btn-primary-solid"
            onClick={saveCampaign}
            disabled={upsertCampaignLoading || (selectedAds.length === 0 && selectedBrandedObjects.length === 0)}
          >
            {upsertCampaignLoading ? <Spinner size="sm" /> : 'Next'}
          </button>
        </div>
      </div>
      <Modal isOpen={isModalOpen}>
        <CreateAdModal
          setFormData={setFormData}
          refetch={refetchAds}
          adCampaignId={adCampaignId}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </div>
  );
};

export default SelectAd;

import { useMemo, useState } from 'react';
import { handleError } from '../../../utils';
import { Dispatcher, OrgType, OrganizationData, Refetch, UserData } from '../../../global/interfaces';
import { useErrorContext } from '../../../context/error-context';
import { useReneMutation } from '../../../hooks';
import { ADD_USER_TO_ORG_MUTATION } from '../../../global/gql/mutations';
import { SearchUserWithDropdown } from '../../search-user/search-user';
import { ApolloError, ApolloQueryResult, OperationVariables } from '@apollo/client';
import { UserRole } from '../../../global/consts';
import Icon from '../../Icon/Icon';
import User from '../../user/user';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import successImg from '../../../global/images/success.webp';

import './invite-member-modal.scss';

const roles = [UserRole.ADMIN, UserRole.DEVELOPER, UserRole.ADVERTISER, UserRole.CREATOR];

const ConfirmInviteMember = ({
  user,
  orgType,
  goBack,
  setConfirmed,
  refetch,
}: {
  user: UserData;
  orgType: OrgType;
  goBack: Dispatcher<UserData | undefined>;
  setConfirmed: Dispatcher<boolean>;
  refetch: Refetch<{
    Organization: OrganizationData;
  }>;
}) => {
  const { setError } = useErrorContext();
  const [role, setRole] = useState<UserRole | ''>('');
  const [addUserToOrg, { loading }] = useReneMutation(ADD_USER_TO_ORG_MUTATION, {
    variables: {
      role,
      userId: user.userId,
    },
    onCompleted() {
      refetch();
      setConfirmed(true);
    },
    onError: (err: ApolloError) => {
      if (err.message === 'Could not add user to org, please verify that you are using a valid token.') {
        return handleError(err, setError, 'User already a member of some organization');
      }
      handleError(err, setError);
    },
  });

  const filterSelectRoleOptions = useMemo(() => {
    switch (orgType) {
      case 'GENERAL':
        return roles;
      case 'GAME':
        return [UserRole.ADMIN, UserRole.DEVELOPER];
      case 'ADVERTISEMENT':
        return [UserRole.ADMIN, UserRole.ADVERTISER];
      case 'CREATOR':
        return [UserRole.ADMIN, UserRole.CREATOR];
    }
  }, [orgType]);

  const handleAddUserToOrg = () => {
    addUserToOrg();
  };

  const handleSelectRole = (role: number) => {
    setRole(filterSelectRoleOptions[role]);
  };

  return (
    <div className="confirm-invite-member">
      <p>Do you want to invite this user to your organization?</p>
      <User user={user} />
      <Select label="Select role" value={role} options={filterSelectRoleOptions} changeHandler={handleSelectRole} />
      <div className="confirm-invite-member__actions">
        <button type="button" className="btn-neutral-ghost" onClick={() => goBack(undefined)} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleAddUserToOrg} disabled={!role}>
          {loading ? <Spinner size="sm" /> : 'Yes'}
        </button>
      </div>
    </div>
  );
};

const MemberInvited = ({
  user,
  goBack,
}: {
  user: UserData;
  goBack: Dispatcher<'remove' | 'transfer' | 'invite' | undefined>;
}) => {
  return (
    <div className="member-invited">
      <div className="member-invited__header">
        <button type="button" onClick={() => goBack(undefined)}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <img src={successImg} alt="success" />
      <p>
        <span>
          {user.data.firstName} {user.data.lastName}
        </span>
        has been added to your organization!
      </p>
      <button type="button" className="btn-primary-solid" onClick={() => goBack(undefined)}>
        Back
      </button>
    </div>
  );
};

const InviteMemberModal = ({
  orgId,
  orgType,
  setIsModalOpen,
  refetch,
}: {
  orgId: string;
  setIsModalOpen: Dispatcher<'remove' | 'transfer' | 'invite' | undefined>;
  orgType: OrgType;
  refetch: (variables?: OperationVariables | undefined) => Promise<
    ApolloQueryResult<{
      Organization: OrganizationData;
    }>
  >;
}) => {
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [confirmed, setConfirmed] = useState(false);

  if (confirmed) {
    return <MemberInvited user={selectedUser as UserData} goBack={setIsModalOpen} />;
  }

  return (
    <div className="invite-member-modal">
      <div className="invite-member-modal__header">
        <h2>Invite member</h2>
        <button type="button" onClick={() => setIsModalOpen(undefined)}>
          <Icon name="close" size={24} />
        </button>
      </div>
      {!selectedUser ? (
        <SearchUserWithDropdown
          title="Enter Username"
          description="Please input the name of the user that you want to invite"
          setSelectedUser={setSelectedUser}
          isMember={false}
          orgId={orgId}
          excludeCurrent
        />
      ) : (
        <ConfirmInviteMember
          user={selectedUser}
          orgType={orgType}
          setConfirmed={setConfirmed}
          goBack={setSelectedUser}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default InviteMemberModal;

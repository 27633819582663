import { FC, useState } from 'react';
import { Dispatcher, Event } from '../../global/interfaces';
import { IMAGE_VALIDATION_REGEX } from '../../global/consts';
import Icon from '../Icon/Icon';
import './file-upload.scss';
import SmallErrorMsg from '../small-error-msg/small-error-msg';

interface Props {
  label?: string;
  description?: string;
  setFiles: Dispatcher<File[]> | ((files: File[]) => void);
  multiple?: boolean;
  errorMessage?: string;
  handleBeforeChange?: (e: Event['Input']) => any;
  handleChange?: (e: Event['Input']) => any;
  loadedImgSrc?: string;
  preview?: boolean;
}

const FileUpload: FC<Props> = ({
  label,
  description = 'Drag and drop files or click here to select',
  setFiles,
  multiple,
  errorMessage,
  handleBeforeChange,
  handleChange,
  loadedImgSrc,
  preview = true,
}) => {
  const [upImages, setUpImages] = useState<string[] | []>();
  const [upFiles, setUpFiles] = useState<{ name: string; ext: string }[]>([]);

  const showSelection = (allowedFiles: File[]) => {
    const images: string[] = [];
    const files: { name: string; ext: string }[] = [];
    Object.values(allowedFiles).forEach((file: File) => {
      if (file.type.match(IMAGE_VALIDATION_REGEX)) {
        images.push(URL.createObjectURL(file));
        // TODO - should we add additional validation here?
        // currently we are checking the validTypes only
      } else {
        files.push({ name: file.name, ext: file.type });
      }
    });
    setUpImages(images.length ? images : []);
    setUpFiles(files.length ? files : []);
  };

  const handleFile = (e: Event['Input']) => {
    if (typeof handleBeforeChange === 'function') {
      if (!handleBeforeChange(e)) return;
    }

    const files = e.target.files || [];
    const newFiles = Array.from(files);

    setFiles(newFiles);
    showSelection(newFiles);

    if (!newFiles.length) {
      setFiles([]);
      setUpImages([]);
    }
    if (typeof handleChange === 'function') handleChange(e);
  };

  return (
    <div className={`file-upload-container`}>
      {label ? <label>{label}</label> : null}
      <div className="file-upload-container__input">
        <input type="file" multiple={multiple} onChange={handleFile} />
        {!upImages?.length && (!preview || multiple || !upFiles.length) && (
          <>
            <Icon name="upload" />
            {description && <p>{description}</p>}
          </>
        )}
        <div className="file-upload-container__preview">
          {upImages?.map((image, i) => (
            <img key={i} src={image} alt="new game" />
          ))}
          {preview &&
            !multiple &&
            upFiles?.map((file) => (
              <div key={file.name} className="files">
                <Icon name="file" />
                <p>{file.name}</p>
              </div>
            ))}
          {preview && !upFiles.length && !upImages?.length && loadedImgSrc && <img src={loadedImgSrc} alt="loaded" />}
        </div>
      </div>
      <SmallErrorMsg message={errorMessage} />
    </div>
  );
};

export default FileUpload;

import { format } from 'date-fns';
import { CampaignData, Event } from '../../global/interfaces';
import { CampaignStatusLabel } from '../../global/consts';
import Icon from '../Icon/Icon';
import SmallDashboardBar from '../small-dashboard-bar/small-dashboard-bar';
import './single-campaign.scss';

type SingleCampaignProps = {
  campaign: CampaignData;
  userIsDemo: boolean;
  handleCleanCampaign?: (e: Event['Button']) => void;
  showImage?: boolean;
  showAdsDetails?: boolean;
};

const SingleCampaign = ({
  campaign,
  userIsDemo,
  handleCleanCampaign,
  showImage = true,
  showAdsDetails = true,
}: SingleCampaignProps) => {
  const startDate = campaign.startDate ? format(new Date(campaign.startDate), 'MMM dd, yyyy') : '';
  const endDate = campaign.endDate ? format(new Date(campaign.endDate), 'MMM dd, yyyy') : '';
  return (
    <div className="single-campaign">
      <SmallDashboardBar
        id={campaign.adCampaignId}
        key={campaign.adCampaignId}
        linkTo={`/org/campaigns/${campaign.adCampaignId}`}
        name={campaign.name}
        showImage={showImage}
        description={campaign?.brand?.name}
      >
        <div className="small-dashboard-bar__campaign">
          <p className="small-dashboard-bar__campaign_time">
            <span className={campaign.status}>{CampaignStatusLabel[campaign.status]}</span>
            {startDate && endDate ? `${startDate} - ${endDate}` : 'mm/dd/yyyy - mm/dd/yyyy'}
          </p>
          <div className="small-dashboard-bar__campaign_stats">
            {showAdsDetails && (
              <p className={`${!campaign.totalAdCount ? 'small-dashboard-bar__campaign_stats--no-ads' : ''}`}>
                {campaign.totalAdCount !== '0' ? `${campaign.totalAdCount} Ads` : 'No Ads'}
              </p>
            )}
            <p>
              {campaign?.usedImpressions}
              <Icon name="eye" />
            </p>
            <p>${campaign?.usedCost}</p>
          </div>
          {campaign.isDemo && userIsDemo ? (
            <button
              name={campaign.adCampaignId}
              className="primary-btn small-dashboard-bar__campaign_clean"
              onClick={handleCleanCampaign}
            >
              X
            </button>
          ) : null}
        </div>
      </SmallDashboardBar>
    </div>
  );
};

export default SingleCampaign;

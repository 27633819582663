import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  OrganizationCampaignPage,
  OrganizationCampaignsPage,
  OrganizationGamePage,
  OrganizationGamesPage,
  OrganizationSettingsPage,
} from '../..';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import { UserRole } from '../../../global/consts';
import { GET_USER_QUERY } from '../../../global/gql/queries';
import { UserData } from '../../../global/interfaces';
import { BRANDS, CAMPAIGNS, GAMES, ORG_MANAGEMENT, SETTINGS } from '../../../global/routes';
import { useReneQuery } from '../../../hooks/useReneQuery';
import OrganizationBrandsPage from '../organization-brands-page/organization-brands-page';
import OrganizationDashboardPage from '../organization-dashboard-page/organization-dashboard-page';

import './organization-view-page.scss';

const OrganizationViewPage = () => {
  const navigate = useNavigate();
  const { data } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  useEffect(() => {
    if (data?.User.role && data?.User.role === UserRole.GAMER) {
      navigate(-1);
    }

    if (data?.User && !data.User.orgId) {
      navigate(`${ORG_MANAGEMENT}`);
    }
  }, [data, navigate]);

  if (!data) {
    return <LoadingFallback />;
  }

  return (
    <div className="organization-view-page">
      <Routes>
        <Route index element={<OrganizationDashboardPage />} />
        <Route path={`${GAMES}`} element={<OrganizationGamesPage />} />
        <Route path={`${GAMES}/:gameId/:menuItem`} element={<OrganizationGamePage />} />
        <Route path={`${CAMPAIGNS}`} element={<OrganizationCampaignsPage />} />
        <Route path={`${CAMPAIGNS}/reach/`} element={<OrganizationCampaignsPage reachDrawerOpen={true} />} />
        <Route path={`${CAMPAIGNS}/:adCampaignId/`} element={<OrganizationCampaignPage />} />
        <Route path={`${CAMPAIGNS}/:adCampaignId/:adId`} element={<OrganizationCampaignPage />} />
        <Route path={`${BRANDS}`} element={<OrganizationBrandsPage />} />
        <Route path={`${SETTINGS}/:menuItem/*`} element={<OrganizationSettingsPage />} />
      </Routes>
    </div>
  );
};

export default OrganizationViewPage;

import placeholder from '../../../global/images/avatar.webp';
import './org-banner.scss';

const OrgBanner = ({
  image,
  title,
  children,
}: {
  image: string | undefined;
  title: string | undefined;
  children?: JSX.Element;
}) => {
  return (
    <div className="org-banner">
      <img src={image || placeholder} alt="organization" />
      <h1>{title}</h1>
      <div className="org-banner__stats">{children}</div>
    </div>
  );
};

export default OrgBanner;

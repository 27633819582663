import { format } from 'date-fns';
import { BalanceItemData } from '../../global/interfaces';
import StatusTag from '../status-tag/status-tag';
import './top-ups-list.scss';

type TopUpListItemProps = {
  transaction: BalanceItemData;
};

const TopUpListItem = ({ transaction }: TopUpListItemProps) => (
  <div className="top-up-list_item">
    <div className="top-up-list_item_info">
      <StatusTag status={transaction.status}>{transaction.status?.toLowerCase()}</StatusTag>
      <p className="amount">${transaction.amount}</p>
      <p>{format(new Date(transaction.date), 'MMM dd, yyyy')}</p>
    </div>
    <p>Transaction ID: {transaction.transactionId}</p>
  </div>
);

type TopUpsListProps = {
  transactions: BalanceItemData[];
};

const TopUpsList = ({ transactions }: TopUpsListProps) => (
  <div className="top-up-list">
    {transactions.map((t) => (
      <TopUpListItem key={t.transactionId} transaction={t} />
    ))}
  </div>
);

export default TopUpsList;

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReneQuery } from '../../../hooks';
import { isRoleAllowed } from '../../../utils';
import { ORG, SETTINGS } from '../../../global/routes';
import { GET_ORG_QUERY, GET_ORG_WITH_TRANSACTIONS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { MenuItems, OrgType, OrganizationData, UserData } from '../../../global/interfaces';
import { UserRole } from '../../../global/consts';
import Credit from './credits/credit';
import Members from './members/members';
import AsideMenu from '../../../components/aside-menu/aside-menu';
import Statistics from '../../../components/statistics/statistics';
import AsidePopupMenu from '../../../components/aside-popup-menu/aside-popup-menu';
import SmallDashboardBar from '../../../components/small-dashboard-bar/small-dashboard-bar';
import OrganizationSettings from './organization-settings/organization-settings';

import placeholder from '../../../global/images/avatar.webp';

import './organization-settings-page.scss';

const menuItems: MenuItems = {
  organization: { name: 'organization', icon: 'organization', label: 'Info' },
  members: { name: 'members', icon: 'users', label: 'Members' },
  credit: { name: 'credit', icon: 'credit', label: 'Credit' },
};

const OrganizationStudioSettings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { data: fetchedOrg, refetch } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);
  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const allowEditOrg = isRoleAllowed(user?.User.role as UserRole);

  const renderSelectedMenuItem = (selectedMenuItem: string | undefined) => {
    switch (selectedMenuItem) {
      case 'organization':
        return (
          <OrganizationSettings
            allowEditOrg={user?.User.role === UserRole.OWNER}
            organization={fetchedOrg?.Organization}
            refetch={refetch}
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );
      case 'members':
        return (
          <Members
            orgId={fetchedOrg?.Organization.orgId}
            orgName={fetchedOrg?.Organization.name}
            orgType={fetchedOrg?.Organization.type as OrgType}
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );
      case 'credit':
        return (
          <Credit
            allowTopUp={allowEditOrg}
            query={GET_ORG_WITH_TRANSACTIONS_QUERY}
            redirectPage="org/settings"
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );
      default:
        break;
    }
  };

  const handleMenuItemChange = (value: string) => {
    navigate(`/${ORG}/${SETTINGS}/${menuItems[value].name}`);
  };

  return (
    <div className="organization-settings-page">
      <div className="organization-settings-page__banner">
        <SmallDashboardBar
          id=""
          name={fetchedOrg?.Organization?.name as string}
          imageUrl={fetchedOrg?.Organization?.image?.url}
        >
          <div className="small-dashboard-bar__stats">
            <Statistics
              data={{
                campaigns: fetchedOrg?.Organization.stats.adCampaigns,
                games: fetchedOrg?.Organization.stats.games,
              }}
            />
          </div>
        </SmallDashboardBar>
        <div className="organization-settings-page__banner_big">
          <img src={fetchedOrg?.Organization?.image?.url || placeholder} alt="collection" />
          <div>
            <h3>{fetchedOrg?.Organization?.name}</h3>
            <div>
              <p>{fetchedOrg?.Organization.stats.adCampaigns} Campaigns</p>
              <p>{fetchedOrg?.Organization.stats.games} Games</p>
            </div>
          </div>
        </div>
      </div>
      <div className="organization-settings-page__main">
        <AsidePopupMenu isMenuOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
          <AsideMenu
            items={menuItems}
            selectedItem={params.menuItem as string}
            handleItemSelect={handleMenuItemChange}
          />
        </AsidePopupMenu>
        <section className="organization-settings-page__main_section">
          {renderSelectedMenuItem(params.menuItem)}
        </section>
      </div>
    </div>
  );
};

export default OrganizationStudioSettings;

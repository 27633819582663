import { useState } from 'react';
import { useUpdatePopUpContext } from '../../../context/update-message-context';
import { AdResolutionIab, AdSurfaceInteractivity, AdType } from '../../../global/consts';
import { UPSERT_AD_SURFACE_MUTATION } from '../../../global/gql/mutations';
import { GET_FLOOR_PRICES } from '../../../global/gql/queries';
import { AdSurfaceData, Content, Dispatcher, Event, FloorPricePerAd } from '../../../global/interfaces';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import Icon from '../../Icon/Icon';
import AdSurfaceFields from '../../asset/ad-surface-list/ad-surface/ad-surface-fields';
import { videoValidations } from './validations';

import './new-ad-surface-modal.scss';

interface ModalProps {
  id: string | undefined;
  adType: AdType;
  assetType: 'ownableAssetId' | 'brandedAssetId' | 'gameId';
  handleAdSurface: (form: any) => void;
  setCloseModal: Dispatcher<AdType | undefined>;
}

const NewAdSurfaceModal: React.FC<ModalProps> = ({ id, adType, assetType, handleAdSurface, setCloseModal }) => {
  const uploadFile = useFileUpload();

  const [form, setForm] = useState<{
    adType: AdType;
    resolutionIab: AdResolutionIab;
    interactivity: AdSurfaceInteractivity;
    contentTags: Content[];
    file?: File | undefined;
    maxWidth: string;
    maxHeight: string;
    floorPrice?: number;
  }>({
    adType,
    resolutionIab: AdResolutionIab.v120x20,
    interactivity: AdSurfaceInteractivity.INTERACTIVE,
    contentTags: [],
    maxWidth: '',
    maxHeight: '',
    floorPrice: undefined,
    file: undefined,
  });
  const { isFormInvalid, isFieldInvalid } = useValidation(videoValidations);
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();

  const [upsertAdSurface, { loading }] = useReneMutation(UPSERT_AD_SURFACE_MUTATION, {
    onCompleted(data: { UpsertAdSurface: AdSurfaceData }) {
      if (form?.file && data.UpsertAdSurface.placeholder?.uploadUrl) {
        uploadFile(data.UpsertAdSurface.placeholder.uploadUrl, form.file).finally(() => {
          handleCloseModal();
        });
      }
    },
  });

  useReneQuery<{ GetFloorPrice: FloorPricePerAd[] }>(GET_FLOOR_PRICES, {
    onCompleted: (data) => {
      if (data && data.GetFloorPrice?.length > 0) {
        const prices = data.GetFloorPrice;
        setForm((prev) => ({
          ...prev,
          floorPrice: prices.find((item) => item.adType.toLowerCase() === adType.toLowerCase())?.price || undefined,
        }));
      }
    },
  });

  const handleCloseModal = () => {
    setCloseModal(undefined);
  };

  const handleFileChange = (e: Event['Input']) => {
    const file = e.target.files?.[0];
    if (isFieldInvalid('file', { ...form, adType, file })) return;
  };

  const handleAddSurface = () => {
    if (isFormInvalid({ ...form, adType })) return;

    let variables = {
      ...form,
      [assetType]: id,
      placeholder: form.file ? { extension: form?.file?.type.split('/')[1] } : undefined,
    };

    upsertAdSurface({ variables }).then(({ data }: { data: { UpsertAdSurface: AdSurfaceData } }) => {
      handleAdSurface({
        ...form,
        adSurfaceId: data?.UpsertAdSurface?.adSurfaceId,
        adType: data.UpsertAdSurface.adType,
        placeholder: { url: data.UpsertAdSurface?.placeholder?.url },
      });
      showUpdatePopUpMessage('Changes saved successfully');
    });
    handleCloseModal();
  };

  return (
    <div className="new-ad-surface-video-modal">
      <div className="new-ad-surface-video-modal__heading">
        <h2>
          New <span>{adType}</span> ad surface
        </h2>
        <button type="button" onClick={handleCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="new-ad-surface-video-modal__body">
        <AdSurfaceFields loading={loading} surface={form} handleFileChange={handleFileChange} updateSurface={setForm} />
      </div>
      <div className="new-ad-surface-video-modal__actions">
        <button className="btn-neutral-solid" type="button" onClick={handleCloseModal}>
          Cancel
        </button>
        <button className="btn-primary-solid" type="button" onClick={handleAddSurface}>
          Add
        </button>
      </div>
    </div>
  );
};

export default NewAdSurfaceModal;

import { REMOVE_USER_TO_ORG_MUTATION } from '../../../global/gql/mutations';
import { Dispatcher, OrganizationData, Refetch, UserData } from '../../../global/interfaces';
import { useReneMutation } from '../../../hooks';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import User from '../../user/user';
import './remove-member-modal.scss';

const RemoveMemberModal = ({
  orgId,
  member,
  setIsModalOpen,
  refetch,
}: {
  orgId: string | undefined;
  member: UserData | undefined;
  setIsModalOpen: Dispatcher<'remove' | 'transfer' | 'invite' | undefined>;
  refetch: Refetch<{
    Organization: OrganizationData;
  }>;
}) => {
  const [removeUserToOrg, { loading }] = useReneMutation(REMOVE_USER_TO_ORG_MUTATION, {
    variables: {
      orgId,
      userId: member?.userId,
    },
    onCompleted() {
      refetch();
      setIsModalOpen(undefined);
    },
  });

  return (
    <div className="remove-member-modal">
      <div className="remove-member-modal__heading">
        <button type="button" onClick={() => setIsModalOpen(undefined)}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <p>Are you sure you want to remove this member?</p>
      <User user={member as UserData} />
      <div className="remove-member-modal__actions">
        <button
          type="button"
          className="btn-neutral-ghost"
          onClick={() => setIsModalOpen(undefined)}
          disabled={loading}
        >
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={() => removeUserToOrg()}>
          {loading ? <Spinner size="sm" /> : 'Yes'}
        </button>
      </div>
    </div>
  );
};

export default RemoveMemberModal;

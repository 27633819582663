import { RadioButton } from './radio-button';

import './radio-button.scss';

type RadioButtonsProps<T> = {
  label: string;
  options: {
    label: string;
    value: T;
  }[];
  selectedValue: T;
  setSelectedValue: (value: T) => void;
};

const RadioButtons = <T extends string | number>({
  label,
  options,
  selectedValue,
  setSelectedValue,
}: RadioButtonsProps<T>) => {
  return (
    <div className={'radio-buttons'}>
      <label>{label}</label>
      <div className="radio-buttons_options">
        {options.map((option) => (
          <RadioButton
            id={option.value.toString()}
            name={label}
            key={option.value}
            label={option.label}
            value={option.value}
            setValue={() => setSelectedValue(option.value)}
            isSelected={selectedValue === option.value}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioButtons;

import { useCallback, useEffect, useState } from 'react';
import { UserRole } from '../../../global/consts';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';
import { CAMPAIGNS_SEARCH_QUERY, GET_AD_CAMPAIGNS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { CampaignData, CampaignsData, Event, UserData } from '../../../global/interfaces';
import { useLazyReneQuery, useReneMutation } from '../../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { isRoleAllowed } from '../../../utils';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import Drawer from '../../../components/drawer/drawer';
import Search from '../../../components/search/search';
import CampaignsList from '../../../components/campaigns-list/campaigns-list';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import ReachDrawerContent from './reach-drawer-content/reach-drawer-content';
import CreateCampaignModal from '../../../components/modal/create-campaign-modal/create-campaign-modal';

import './organization-campaigns-page.scss';

const OrganizationCampaignsPage = ({ reachDrawerOpen }: { reachDrawerOpen?: boolean }) => {
  const [adCampaignSearchTerm, setSearchTerm] = useState('');

  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState<boolean>(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const {
    data,
    loading: campaignsLoading,
    refetch,
  } = useReneQuery<{ AdCampaigns: CampaignsData } | undefined>(GET_AD_CAMPAIGNS_QUERY);

  const [search, { data: searchCampaigns, loading: searchLoading }] = useLazyReneQuery<{
    AdCampaignSearch: CampaignsData;
  }>(CAMPAIGNS_SEARCH_QUERY);

  const [upsertCampaign] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted() {
      refetch();
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (adCampaignSearchTerm) {
      search({ variables: { adCampaignSearchTerm } });
    }
  }, [adCampaignSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const handleCleanCampaign = (e: Event['Button']) => {
    e.stopPropagation();
    e.preventDefault();
    upsertCampaign({
      variables: {
        isDemo: false,
        adCampaignId: e.currentTarget.name,
      },
    });
  };

  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, [UserRole.ADVERTISER]);
  const showCampaignData =
    adCampaignSearchTerm && searchCampaigns ? searchCampaigns.AdCampaignSearch.items : data?.AdCampaigns.items;

  return (
    <div className="organization-campaigns-page">
      <div className="organization-campaigns-page__heading">
        <div className="organization-campaigns-page__heading_title">
          <h1>Create campaign</h1>
          <Link to="reach">
            <Icon name="user" size={24} />
            Estimate campaign reach <Icon name="chevron-right" />
          </Link>
        </div>
        <div className="organization-campaigns-page__heading_actions">
          <Search callback={handleSearch} apiSearch />
          {isUserAllowed && (
            <button
              type="button"
              className="btn-primary-solid organization-campaigns-page__heading_actions_new"
              onClick={() => setIsNewCampaignModalOpen(true)}
            >
              <Icon name="plus" size={24} />
              <p>New Campaign</p>
            </button>
          )}
        </div>
      </div>
      <div className="organization-campaigns-page__content">
        <div className="organization-campaigns-page__content_campaigns">
          {campaignsLoading || searchLoading ? (
            <LoadingFallback />
          ) : (
            <CampaignsList
              campaigns={showCampaignData as CampaignData[]}
              userIsDemo={!isUserAllowed}
              handleCleanCampaign={handleCleanCampaign}
            />
          )}
        </div>
      </div>
      <Drawer title={'Estimate reach'} isOpen={!!reachDrawerOpen} onClose={() => navigate(-1)}>
        <ReachDrawerContent />
      </Drawer>
      <Modal isOpen={isNewCampaignModalOpen}>
        <CreateCampaignModal refetch={refetch} setCloseModal={() => setIsNewCampaignModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationCampaignsPage;

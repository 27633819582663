import Icon from '../Icon/Icon';
import './checkbox.scss';

const Checkbox = ({
  value,
  children,
  setValue,
}: {
  value: boolean;
  children?: JSX.Element | string;
  setValue: (value: boolean) => void;
}) => {
  return (
    <div className={`checkbox-container`}>
      <button className={`checkbox checkbox${value ? '--checked' : ''}`} type="button" onClick={() => setValue(!value)}>
        {value ? <Icon name="checkmark" size={8} /> : null}
      </button>
      {children}
    </div>
  );
};

export default Checkbox;

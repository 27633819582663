import { Dispatcher } from '../../../global/interfaces';
import Checkbox from '../../checkbox/checkbox';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Textarea from '../../textarea/textarea';
import './create-offer-modal.scss';

interface CreateOfferModalProps {
  setIsModalOpen: Dispatcher<string>;
}

export const CreateOfferModal: React.FC<CreateOfferModalProps> = ({ setIsModalOpen }) => {
  const handleCloseModal = () => {
    setIsModalOpen('');
  };

  return (
    <div className="create-offer-modal">
      <div className="create-offer-modal__heading">
        <h2>Create offer</h2>
        <button onClick={handleCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <h3>
        Reward users with exclusive deals or promotions when they engage with your ad, increasing the chances of
        conversion and enhancing user experience.
      </h3>
      <div className="create-offer-modal__content">
        <Select showError label="Sender" value="" options={[]} changeHandler={() => {}} />
        <Input label="Title" value="" placeholder="Enter title" handleInput={() => {}} />
        <Textarea label="Message" value="dasdas" placeholder="Enter message" handleInput={() => {}} />
        <FileUpload label="Visual (optional)" setFiles={() => {}} />
        <Checkbox value={false} setValue={() => {}}>
          Show CTA button
        </Checkbox>
        <div className="create-offer-modal__content_cta">
          <Input label="Button URL" value="" placeholder="Enter CTA button text" handleInput={() => {}} />
          <Input label="Button text" value="" placeholder="Enter CTA button text" handleInput={() => {}} />
        </div>
      </div>

      <div className="create-offer-modal__footer">
        <div className="create-offer-modal__footer-left">
          <button className="btn-neutral-ghost">Preview offer</button>
        </div>
        <div className="create-offer-modal__footer-right">
          <button className="btn-neutral-ghost" onClick={handleCloseModal}>
            Cancel
          </button>
          <button className="btn-primary-solid">Finish</button>
        </div>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { REFRESH_TOKEN, UPSERT_ORG_MUTATION } from '../../../global/gql/mutations';
import { Event, OrganizationData, Refetch, UserData } from '../../../global/interfaces';
import { useReneMutation, useValidation } from '../../../hooks';
import { PRIVACY_POLICY } from '../../../global/routes';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { validations } from './validations';
import { TOKEN, orgTypeOptions } from '../../../global/consts';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Checkbox from '../../checkbox/checkbox';
import Textarea from '../../textarea/textarea';

import './new-organization-modal.scss';

interface Props {
  refetch: Refetch<{
    User: UserData;
  }>;
  setCloseModal: () => void;
}

const NewOrganizationModal: React.FC<Props> = ({ refetch, setCloseModal }) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{
    name: string;
    type: { title: string; description: string; key: string } | undefined;
    description: string;
    terms: boolean;
    file: File | undefined;
  }>({
    name: '',
    description: '',
    terms: false,
    file: undefined,
    type: undefined,
  });

  const [refreshToken] = useReneMutation(REFRESH_TOKEN, {
    async onCompleted(data: { RefreshToken: { jwt: string } }) {
      if (data.RefreshToken.jwt) {
        await localStorage.setItem(TOKEN, data.RefreshToken.jwt);
        await refetch();
        setLoading(false);
        setCloseModal();
      }
    },
  });

  const [addOrganization] = useReneMutation(UPSERT_ORG_MUTATION, {
    errorPolicy: 'all',
    onCompleted(data: { UpsertOrganization: OrganizationData }) {
      if (form.file) {
        uploadFile(data.UpsertOrganization.image.uploadUrl, form.file).finally(() => {
          refreshToken();
        });
      }
    },
  });

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCheckBox = (value: boolean) => {
    setForm((prev) => ({ ...prev, terms: value }));
  };

  const handleAddingNewOrg = () => {
    if (isFormInvalid(form)) return;
    const variables = {
      name: form.name,
      data: {
        description: form.description,
      },
      type: form?.type?.key,
      image: { extension: form.file?.type.split('/')[1] },
      isActive: true,
    };
    setLoading(true);
    addOrganization({ variables });
  };

  return (
    <div className="new-organization-modal">
      <div className="new-organization-modal__heading">
        <h2>New Organization</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter organization name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter organization description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <Select
        label="Type"
        value={form.type?.title || ''}
        placeholder="Select organization type"
        options={orgTypeOptions}
        changeHandler={(value) => setForm((prev) => ({ ...prev, type: orgTypeOptions[value] }))}
        showListValueFn={(value) => (
          <div className="select__org-type">
            <p>{value.title}</p>
            <p>{value.description}</p>
          </div>
        )}
        errorMsg={errors?.type}
        showError
      />
      <FileUpload
        setFiles={(files: File[]) => setForm((prev) => ({ ...prev, file: files?.[0] }))}
        label="Organization Picture"
        errorMessage={errors?.file}
      />
      <div className="new-organization-modal__terms">
        <div>
          <Checkbox value={form.terms} setValue={handleCheckBox} />
          <p>
            I accept your
            <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </p>
        </div>
        <p>{errors?.terms}</p>
      </div>
      <div className="new-organization-modal__actions">
        <button type="button" className="btn-neutral-ghost" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleAddingNewOrg}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewOrganizationModal;

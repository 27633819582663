import { useState } from 'react';
import { useReneQuery } from '../../../../hooks';
import { UserRole } from '../../../../global/consts';
import { GET_ORG_MEMBERS, GET_USER_QUERY } from '../../../../global/gql/queries';
import { Dispatcher, OrgType, OrganizationData, UserData } from '../../../../global/interfaces';
import { isRoleAllowed } from '../../../../utils';
import User from '../../../../components/user/user';
import Icon from '../../../../components/Icon/Icon';
import Modal from '../../../../components/modal/modal';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import RemoveMemberModal from '../../../../components/modal/remove-member-modal/remove-member-modal';
import InviteMemberModal from '../../../../components/modal/invite-member-modal/invite-member-modal';
import TransferOrgOwnershipModal from '../../../../components/modal/transfer-org-ownership-modal/transfer-org-ownership-modal';

import './members.scss';

const Member = ({
  owner,
  member,
  currentUserIsOwner,
  handleUserAction,
}: {
  owner?: boolean;
  member: UserData;
  currentUserIsOwner?: boolean;
  handleUserAction: (member: UserData, modal: 'remove' | 'transfer' | 'invite') => void;
}) => {
  return (
    <div className="member">
      <User user={member} />
      {owner && <div className="member__owner">Owner</div>}
      {!owner && (
        <div className="member__action">
          <p>{member.role}</p>
          {currentUserIsOwner && (
            <button name={member.userId} type="button" onClick={() => handleUserAction(member, 'remove')}>
              <Icon name="trash" size={16} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const Members = ({
  orgId,
  orgType,
  orgName,
  setOpenMobileMenu,
}: {
  orgId: string | undefined;
  orgName: string | undefined;
  orgType: OrgType;
  setOpenMobileMenu: Dispatcher<boolean>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<'remove' | 'transfer' | 'invite'>();
  const [selectedMember, setSelectedMember] = useState<UserData>();
  const { data: members, loading, refetch } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_MEMBERS);

  const orgMembers = members?.Organization.members.items?.reduce(
    (acc: { owner?: UserData; members: UserData[] }, current) => {
      if (current.role === UserRole.OWNER) {
        acc.owner = current;
      } else {
        acc.members.push(current);
      }
      return acc;
    },
    { owner: undefined, members: [] },
  );
  const { data } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const isAllowToAdMember = data?.User && isRoleAllowed(data?.User?.role);

  const handleUserAction = (member: UserData | undefined, modal: 'remove' | 'transfer' | 'invite') => {
    setSelectedMember(member);
    setIsModalOpen(modal);
  };

  return (
    <div className="members">
      <div className="members__heading">
        <div>
          <button onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
          <h2>Members</h2>
        </div>
        {isAllowToAdMember && (
          <button className="btn-neutral-solid" type="button" onClick={() => handleUserAction(undefined, 'invite')}>
            <Icon name="user-plus" size={24} />
            <p>Invite member</p>
          </button>
        )}
      </div>
      <div className="members__main">
        <div className="members__main_owner">
          {orgMembers?.owner && <Member member={orgMembers.owner} handleUserAction={handleUserAction} owner />}
        </div>
        {orgMembers?.members?.map((member) => (
          <Member
            key={member.userId}
            member={member}
            handleUserAction={handleUserAction}
            currentUserIsOwner={data?.User.role === UserRole.OWNER}
          />
        ))}
        {loading ? <LoadingFallback /> : null}
      </div>
      <Modal isOpen={isModalOpen === 'remove'}>
        <RemoveMemberModal orgId={orgId} member={selectedMember} setIsModalOpen={setIsModalOpen} refetch={refetch} />
      </Modal>
      <Modal isOpen={isModalOpen === 'transfer'}>
        <TransferOrgOwnershipModal orgName={orgName} member={selectedMember} setIsModalOpen={setIsModalOpen} />
      </Modal>
      <Modal isOpen={isModalOpen === 'invite'}>
        <InviteMemberModal
          setIsModalOpen={setIsModalOpen}
          refetch={refetch}
          orgId={orgId as string}
          orgType={orgType}
        />
      </Modal>
    </div>
  );
};

export default Members;

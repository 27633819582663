import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { AdOffer } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import './offer-list.scss';

type OfferListItemProps = {
  offer: AdOffer;
};

const OfferListItem = ({ offer: { adOffer: offer, isViewed } }: OfferListItemProps) => {
  return (
    <Link className={`offer-list-item${isViewed ? ' viewed' : ''}`} to={offer.adOfferId}>
      <div className="offer-list-item_image">
        <img src={offer.adOfferImage?.url} alt={offer.adOfferImage?.name} />
      </div>
      <div className="offer-list-item_content">
        <div className="offer-list-item_content_company">{offer.brand?.name}</div>
        <div className="offer-list-item_content_description">
          <div className="offer-list-item_content_description_title">{offer.title}</div>
          <div className="offer-list-item_content_description_text">{offer.message}</div>
        </div>
        <p className="offer-list-item_content_footer">
          <Icon name="gamepad" />
          {offer.buttonText}
        </p>
      </div>
      <div className="offer-list-item_timestamp">{format(new Date(offer.createdAt), 'MMM dd, yyyy')}</div>
    </Link>
  );
};

export default OfferListItem;

import { useState } from 'react';
import BannerWithDropdown from '../../banner-with-dropdown/banner-with-dropdown';
import Icon from '../../Icon/Icon';
import NewBrandedObjectPlacementModal from '../../modal/new-branded-object-placement-modal/new-branded-object-placement-modal';
import Spinner from '../../spinner/spinner';

import { useApolloClient } from '@apollo/client';
import { DELETE_BRANDED_OBJECT_PLACEMENT_MUTATION } from '../../../global/gql/mutations';
import { BrandedObjectPlacement } from '../../../global/interfaces';
import { useReneMutation } from '../../../hooks';
import { usePricingQuery } from '../../../hooks/usePricingQuery';
import './branded-objects-list.scss';

const BrandedObjectsList = ({
  brandedObjectPlacements,
  loading,
}: {
  brandedObjectPlacements: BrandedObjectPlacement[];
  loading: boolean;
}) => {
  const [showNewBrandedObjectModal, setShowNewBrandedObjectModal] = useState(false);
  const { data: floorPrices } = usePricingQuery({
    input: {
      adTypes: ['THREE_D', 'TWO_D'],
      countries: ['US'],
    },
  });

  const apolloClient = useApolloClient();

  const [deleteBrandedObjectPlacement] = useReneMutation(DELETE_BRANDED_OBJECT_PLACEMENT_MUTATION, {
    onCompleted: async () => {
      await apolloClient.refetchQueries({ include: ['BrandedObjectPlacements'] });
      setDeletingId(null);
    },
    onError: () => {
      setDeletingId(null);
    },
  });

  const [editPlacement, setEditPlacement] = useState<(BrandedObjectPlacement & { gameId: string }) | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deletingId, setDeletingId] = useState<string | null>(null);

  const handleEdit = (brandedObjectPlacement: BrandedObjectPlacement) => {
    setEditPlacement({ ...brandedObjectPlacement, gameId: brandedObjectPlacement.gameId ?? '' });
    setShowEditModal(true);
  };

  return (
    <div className="branded-objects-list">
      <div className="branded-objects-list__header">
        <h1>
          Branded Object Placements <span>{brandedObjectPlacements.length}</span>
        </h1>
        <button className="btn-primary-ghost" onClick={() => setShowNewBrandedObjectModal(true)}>
          <Icon name="plus" /> New placement
        </button>
      </div>
      <div className="branded-objects-list__main">
        {loading ? (
          <Spinner size="md" />
        ) : (
          brandedObjectPlacements.map((brandedObjectPlacement: BrandedObjectPlacement) => (
            <BannerWithDropdown
              key={brandedObjectPlacement.brandedObjectPlacementId}
              title={'Branded Object'}
              subLabel={'Branded Object Placement'}
              id={brandedObjectPlacement.brandedObjectPlacementId ?? ''}
              loading={deletingId === brandedObjectPlacement.brandedObjectPlacementId}
              menuItems={[
                {
                  label: (
                    <>
                      <Icon name="edit" /> Edit
                    </>
                  ),
                  onClick: () => handleEdit(brandedObjectPlacement),
                },
                {
                  label: (
                    <>
                      <Icon name="trash" /> Delete
                    </>
                  ),
                  onClick: () => {
                    setDeletingId(brandedObjectPlacement.brandedObjectPlacementId ?? '');
                    deleteBrandedObjectPlacement({
                      variables: {
                        brandedObjectPlacementId: brandedObjectPlacement.brandedObjectPlacementId,
                      },
                    });
                  },
                },
              ]}
            >
              <div className="branded-objects-list__main__content">
                <p>
                  Dimensions: {brandedObjectPlacement.width ?? '-'} (W) x {brandedObjectPlacement.height ?? '-'} (H) x{' '}
                  {brandedObjectPlacement.length ?? '-'} (D)
                </p>
                <p>IAB Categories: {brandedObjectPlacement.categories?.join(', ') || '-'}</p>
                <p>
                  Floor Price:{' $'}
                  {floorPrices?.Pricing.items.find((p) => p.adType === brandedObjectPlacement.type)?.cpm ?? '-'}
                </p>
              </div>
            </BannerWithDropdown>
          ))
        )}
      </div>
      {showNewBrandedObjectModal && (
        <NewBrandedObjectPlacementModal
          isOpen={showNewBrandedObjectModal}
          onClose={() => setShowNewBrandedObjectModal(false)}
        />
      )}
      {showEditModal && editPlacement && (
        <NewBrandedObjectPlacementModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          editPlacement={editPlacement}
        />
      )}
    </div>
  );
};

export default BrandedObjectsList;

export const validations = {
  contentTags: (_form: { [k: string]: any }, _key: string, field: string[]) => {
    if (!field || (field && !field.length)) return 'Please select at least one IAB category';
    if (field.length > 100) return 'You can select up to 100 IAB categories';
  },
  genres: (_form: { [k: string]: any }, _key: string, field: string[]) => {
    if (!field || (field && !field.length)) return 'Please select at least one genre';
    if (field.length > 10) return 'You can select up to 10 genres';
  },
};

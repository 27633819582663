import { useState } from 'react';
import { useReneQuery } from '../../../hooks';
import { SETTINGS } from '../../../global/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USER_BALANCE_AND_TRANSACTIONS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { MenuItems, UserData } from '../../../global/interfaces';
import Credit from '../../organization/organization-settings-page/credits/credit';
import AsideMenu from '../../../components/aside-menu/aside-menu';
import AsidePopupMenu from '../../../components/aside-popup-menu/aside-popup-menu';
import ProfileSettings from './profile-settings/profile-settings';
import PointsAndRewards from '../points-and-rewards/points-and-rewards';

import './profile-settings-page.scss';

const menuItems: MenuItems = {
  profile: { name: 'profile', icon: 'user', label: 'Profile' },
  points: { name: 'points', icon: 'rene-point', label: 'Points & Rewards' },
  credit: { name: 'credit', icon: 'credit', label: 'Credit' },
};

const ProfileSettingsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { data, refetch } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const handleMenuItemChange = (value: string) => {
    navigate(`/${SETTINGS}/${menuItems[value].name}`);
  };

  const renderSelectedMenuItem = (selectedMenuItem: string | undefined) => {
    switch (selectedMenuItem) {
      case 'profile':
        return <ProfileSettings user={data?.User} refetch={refetch} setOpenMobileMenu={setIsMobileMenuOpen} />;
      case 'points':
        return <PointsAndRewards setOpenMobileMenu={setIsMobileMenuOpen} />;
      case 'credit':
        return (
          <Credit
            query={GET_USER_BALANCE_AND_TRANSACTIONS_QUERY}
            redirectPage="settings"
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="profile_settings_page">
      <div className="profile_settings_page__main">
        <AsidePopupMenu isMenuOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
          <AsideMenu
            items={menuItems}
            selectedItem={params.menuItem as string}
            handleItemSelect={handleMenuItemChange}
          />
        </AsidePopupMenu>
        <section className="profile_settings_page__main_section">{renderSelectedMenuItem(params.menuItem)}</section>
      </div>
    </div>
  );
};

export default ProfileSettingsPage;

import React from 'react';
import Icon from '../Icon/Icon';
import './drawer.scss';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Drawer = ({ isOpen, onClose, title, children }: DrawerProps) => {
  return (
    <>
      {isOpen && <div className="drawer-overlay" onClick={onClose}></div>}
      <div className={`drawer ${isOpen ? 'open' : ''}`}>
        <div className="drawer-header">
          <h2>{title}</h2>
          <button className="drawer-close-button" onClick={onClose}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <div className="drawer-content">{isOpen ? children : null}</div>
      </div>
    </>
  );
};

export default Drawer;

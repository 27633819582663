import { Link } from 'react-router-dom';
import { GAMES, ORG } from '../../../global/routes';
import { GameData } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import placeholder from '../../../global/images/game-placeholder.png';

import './game-banner.scss';

interface Props {
  name: string | undefined;
  description: string | undefined;
  stats: GameData['stats'] | undefined;
  gameImage: string | undefined;
  chain: GameData['chain'] | null | undefined;
  impressions: string | undefined;
  id?: string;
}

const GameBanner: React.FC<Props> = ({ name, description, stats, gameImage, chain, impressions, id }) => {
  return (
    <div className="game-banner">
      <div className="game-banner__back">
        <Link to={`/${ORG}/${GAMES}`}>
          <Icon name="chevron-left" size={16} />
          <span>Back</span>
        </Link>
      </div>
      <div className="game-banner__main">
        <div className="game-banner__main_image">
          <img src={gameImage || placeholder} alt="game" />
        </div>
        <div className="game-banner__main_info">
          <h1>{name}</h1>
          <div className="game-banner__main_info_stats">
            <p>
              ID: <span>{id}</span>
            </p>
            <p>
              {stats?.players} <span>Connected players</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameBanner;

import { useEffect, useState } from 'react';
import { useReneMutation, useLazyReneQuery, useValidation, useReneQuery } from '../../../../../hooks';
import {
  AdData,
  BrandedObjectData,
  CampaignData,
  CampaignsData,
  Dispatcher,
  OrganizationData,
  PricingItem,
  Refetch,
} from '../../../../../global/interfaces';
import { CampaignFormData } from '../../create-campaign-modal';
import { GET_ORG_QUERY, PRICING_QUERY } from '../../../../../global/gql/queries';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../../../global/gql/mutations';
import { AdCampaignCheckpoint, AdTypeLabel, CampaignStatus } from '../../../../../global/consts';
import { formatDate, getUniqueKeyValues } from '../../../../../utils';
import { validations } from './validations';
import Input from '../../../../input/input';
import Modal from '../../../modal';
import Spinner from '../../../../spinner/spinner';
import SmallErrorMsg from '../../../../small-error-msg/small-error-msg';
import TopUpCreditModal from '../../../top-up-credit-modal/top-up-credit-modal';
import './budget.scss';

interface BudgetProps {
  data: {
    startDate: string;
    endDate: string;
    targetCountries: {
      countryCode: string;
      countryName: string;
    }[];
    selectedAds: AdData[];
    selectedBrandedObjects: BrandedObjectData[];
    pricing: PricingItem[];
  };
  status: CampaignStatus;
  setActiveStep: (step: number) => void;
  setFormData: Dispatcher<CampaignFormData>;
  adCampaignId: string;
  handleDeleteAdCampaign: () => void;
  deleteAdCampaignLoading: boolean;
  refetch: Refetch<
    | {
        Organization: OrganizationData;
      }
    | {
        AdCampaigns: CampaignsData;
      }
    | undefined
  >;
}

const Budget: React.FC<BudgetProps> = ({
  data,
  status,
  adCampaignId,
  setActiveStep,
  setFormData,
  handleDeleteAdCampaign,
  deleteAdCampaignLoading,
  refetch,
}) => {
  const { errors, isFormInvalid } = useValidation(validations);
  const [isTopupOpen, setIsTopupOpen] = useState(false);
  const [budgetData, setBudgetData] = useState({
    startDate: formatDate(data.startDate) || '',
    endDate: formatDate(data.endDate) || '',
    pricing: data.pricing || [],
  });
  const adsTypes = getUniqueKeyValues(data.selectedAds, 'adType');
  const brandedObjectsTypes = getUniqueKeyValues(data.selectedBrandedObjects, 'brandedObjectType');
  const { data: orgData, refetch: refetchOrg } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);

  const [getPricing] = useLazyReneQuery(PRICING_QUERY, {
    variables: {
      input: {
        countries: data.targetCountries.map((country) => country.countryCode),
        adTypes: [...adsTypes, ...brandedObjectsTypes],
      },
    },
    onCompleted({ Pricing }: { Pricing: { items: PricingItem[] } }) {
      setBudgetData({
        ...budgetData,
        pricing: Pricing.items,
      });
    },
  });

  const [upsertCampaign, { loading: upsertCampaignLoading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted(data: { UpsertAdCampaign: CampaignData }) {
      setFormData((prev) => ({
        ...prev,
        status: data.UpsertAdCampaign.status as CampaignStatus,
        budget: {
          startDate: data.UpsertAdCampaign.startDate,
          endDate: data.UpsertAdCampaign.endDate,
          pricing: data.UpsertAdCampaign.budget.map((item) => ({
            adType: item.pricingType,
            countryCode: item.countryCode,
            countryName: item.countryName,
            cpm: item.cpm,
            region: item.region,
            totalImpressions: item.totalImpressions,
          })),
        },
      }));
      setActiveStep(5);
      refetch();
    },
  });

  useEffect(() => {
    if (!data.pricing.length) {
      getPricing();
    }
  }, [data, getPricing]);

  const saveCampaign = () => {
    if (isFormInvalid(budgetData)) return;
    let variables = {
      adCampaignId,
      budget: budgetData.pricing.map((item) => ({
        countryCode: item.countryCode,
        impressions: item.totalImpressions,
        pricingType: item.adType,
      })),
      startDate: budgetData.startDate,
      endDate: budgetData.endDate,
      checkpoint: AdCampaignCheckpoint.FINAL_OVERVIEW,
    };
    upsertCampaign({ variables });
  };

  return (
    <div className="budget">
      <div className="budget__content">
        <h2>Budget & Scheduling</h2>
        <h3>Set Your Campaign Budget and Define Ad Scheduling for Optimal Reach.</h3>
        <div className="budget__date">
          <h4>Campaign settings</h4>
          <div className="budget__date_calendar">
            <Input
              label="Start Date"
              type="date"
              name="startDate"
              placeholder="Select start date"
              value={budgetData.startDate}
              handleInput={(e) =>
                setBudgetData({
                  ...budgetData,
                  startDate: e.target.value,
                })
              }
              errorMessage={errors?.startDate}
              disabled={false}
            />
            <Input
              label="End Date"
              type="date"
              name="endDate"
              placeholder="Select end date"
              value={budgetData.endDate}
              handleInput={(e) =>
                setBudgetData({
                  ...budgetData,
                  endDate: e.target.value,
                })
              }
              errorMessage={errors?.endDate}
              disabled={false}
            />
          </div>
        </div>
        <div className="budget__stats">
          <h4>Budget</h4>
          <h5>
            When you create a campaign, we reserve the amount from your credit balance. It is only charged at the end of
            the campaign. If you delete campaign, the amount is refunded to your credit balance.{' '}
          </h5>
          <div className="budget__stats_table">
            <table>
              <thead>
                <tr>
                  <th>Country/Market</th>
                  <th>Ad type</th>
                  <th>Impression price</th>
                  <th>Quantity</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {budgetData.pricing.map((item, index) => (
                  <tr key={index}>
                    <td>{item.countryName}</td>
                    <td>{AdTypeLabel[item.adType]}</td>
                    <td>${item.cpm}</td>
                    <td>
                      <Input
                        type="number"
                        placeholder="Enter quantity"
                        value={item.totalImpressions}
                        handleInput={(e) => {
                          const newPricing = [...budgetData.pricing];
                          newPricing[index] = {
                            ...newPricing[index],
                            totalImpressions: parseInt(e.target.value),
                          };
                          setBudgetData({
                            ...budgetData,
                            pricing: newPricing,
                          });
                        }}
                        hideErrorMessage
                      />
                    </td>
                    <td>${(((item.cpm || 0) * (item.totalImpressions || 0)) / 1000).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <SmallErrorMsg message={errors?.pricing} />
        <div className="budget__total">
          <button className="btn-neutral-solid" onClick={() => setIsTopupOpen(true)}>
            Top-up
          </button>
          <p>Your balance: ${orgData?.Organization.balance?.balance}</p>
          <p>
            Grand total:{' '}
            <span>
              $
              {(
                budgetData.pricing.reduce((acc, item) => acc + (item.cpm || 0) * (item.totalImpressions || 0), 0) / 1000
              ).toFixed(2)}
            </span>
          </p>
        </div>
      </div>
      <div className="create-campaign-modal__main_footer">
        <div>
          {status === CampaignStatus.DRAFT && (
            <button className="btn-negative-ghost" onClick={handleDeleteAdCampaign}>
              {deleteAdCampaignLoading ? <Spinner size="sm" /> : 'Discard'}
            </button>
          )}
        </div>
        <div>
          <button className="btn-neutral-ghost" onClick={() => setActiveStep(3)}>
            Back
          </button>
          <button className="btn-primary-solid" onClick={saveCampaign}>
            {upsertCampaignLoading ? <Spinner size="sm" /> : 'Next'}
          </button>
        </div>
      </div>
      <Modal isOpen={isTopupOpen}>
        <TopUpCreditModal
          balance={orgData?.Organization.balance?.balance as string}
          setIsModalOpen={setIsTopupOpen}
          redirectPage=""
          refetch={refetchOrg}
        />
      </Modal>
    </div>
  );
};

export default Budget;

import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import loaderGif from '../../global/images/loader.gif';
import { useClickOutside } from '../../hooks';
import Input from '../input/input';
import './dropdown-search.scss';

type DropdownSearchProps<T> = {
  label?: string;
  options: {
    label: string;
    value: T;
  }[];
  selectedValue: T;
  handleSelect: (value: T) => void;
  handleSearchChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  name?: string;
  errorMessage?: string;
  emptyInputResets?: boolean;
};

const DropdownSearch = <T,>({
  label,
  options,
  selectedValue,
  handleSelect,
  handleSearchChange,
  placeholder = 'Search...',
  disabled = false,
  loading = false,
  name,
  errorMessage,
  emptyInputResets = false,
}: DropdownSearchProps<T>) => {
  const [searchTerm, setSearchTerm] = useState(
    selectedValue ? options.find((option) => option.value === selectedValue)?.label : '',
  );

  useEffect(() => {
    setSearchTerm(selectedValue ? options.find((option) => option.value === selectedValue)?.label : '');
  }, [selectedValue, options]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setShowDropdown(false));

  const debouncedHandleSearchChange = useMemo(() => debounce(handleSearchChange, 300), [handleSearchChange]);

  useEffect(() => {
    return () => {
      debouncedHandleSearchChange.cancel();
    };
  }, [debouncedHandleSearchChange]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowDropdown(true);
    if (emptyInputResets && value === '') {
      handleSearchChange('');
      return;
    }
    value !== '' && debouncedHandleSearchChange(value);
  };

  const handleOptionSelect = (value: T, label: string) => {
    handleSelect(value);
    setSearchTerm(label);
    setShowDropdown(false);
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    setTimeout(() => {
      if (!event.currentTarget?.contains(document.activeElement)) {
        setShowDropdown(false);
      }
    }, 150);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && focusedOptionIndex !== null) {
      const option = options[focusedOptionIndex];
      handleOptionSelect(option.value, option.label);
    }
  };

  return (
    <div className="dropdown-search" onBlur={handleBlur} tabIndex={-1} ref={ref} onKeyDown={handleKeyDown}>
      <Input
        type="text"
        value={searchTerm}
        handleInput={handleInputChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={disabled}
        className="dropdown-search__input"
        name={name}
        errorMessage={errorMessage}
        label={label}
      >
        {showDropdown && (
          <div className={`dropdown-search__options${label ? ' with-label' : ''}`}>
            {loading && (
              <div className="dropdown-search__loading">
                <img src={loaderGif} alt="loader" />
              </div>
            )}
            {!loading &&
              options.length > 0 &&
              options.map(({ label, value }, index) => (
                <div
                  key={value as unknown as string}
                  onClick={() => handleOptionSelect(value, label)}
                  className={`dropdown-search__option ${value === selectedValue ? 'selected' : ''}`}
                  tabIndex={0}
                  onFocus={() => setFocusedOptionIndex(index)}
                >
                  {label}
                </div>
              ))}
          </div>
        )}
      </Input>
    </div>
  );
};

export default DropdownSearch;
